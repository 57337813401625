// Banner.js
import React, { useRef } from 'react';
import './Banner.css';

function Banner() {
    const bannerRef = useRef(null);

    const scrollToContact = () => {
        const contactSection = document.getElementById('kontakt');
        if (contactSection) {
            const offsetTop = contactSection.offsetTop - 100;
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div>
            <div id="om-oss" ref={bannerRef} className="banner_b">
                <div className="content_b">
                    <h2 className="title_b">Erfaring og Kvalitet: Ditt Foretrukne Byggefirma i Bergen</h2>
                    <p className="banner_p">
Som et anerkjent byggefirma i Bergen, Karsten Bygg AS tilbyr en unik kombinasjon av moderne byggeteknikker og tradisjonelt håndverk. Grunnlagt i 2017, har vi raskt blitt kjent for vår evne til å realisere en rekke byggeprosjekter – fra innovative nybygg til skreddersydde renoveringer og tilbygg. Vår forpliktelse til høyeste kvalitetsstandarder og skreddersydde løsninger har gjort oss til et pålitelig navn for både private og kommersielle kunder i Bergen. Med et sterkt fokus på kundetilfredshet, er vi dedikerte til å bringe dine visjoner til virkelighet med ekspertise og effektivitet.
</p>

                    <button className="btn primary-btn" onClick={scrollToContact}>Kontakt oss</button>
                </div>
            </div>
        </div>
    );
}

export default Banner;
