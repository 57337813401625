import React from 'react';
import './Banner.css'; // This will import the CSS from Banner.css

function Banner2() {
  return (
    <div className="banner_b_2">
      <div className="content_b">
      <h2 className="title_b">Din Partner i Byggeprosjekter – Fra Visjon til Virkelighet i Bergen</h2>
      <p className="banner_p">
Karsten Bygg AS, grunnlagt i 2017, står i spissen for byggebransjen i Bergen, kjent for sin pålitelighet og ekspertise. Vårt talentfulle team, bestående av dyktige tømrere og håndverkere, er forpliktet til å levere førsteklasses byggearbeid for både kommersielle og private prosjekter. Vi kombinerer moderne teknikker med høykvalitets materialer for å skape holdbare og estetisk tiltalende konstruksjoner. Hvert prosjekt vi tar oss av, uavhengig av størrelse, er et bevis på vårt engasjement for kvalitet og vårt mål om å bygge sterke og varige forhold til våre kunder. 
</p>
      </div>
    </div>
  );
}

export default Banner2;
