import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import "./App.css";
import Navbar from './Navbar';
import Home from './Home';
import Cards from './Cards';
import Banner from './Banner';
import Banner2 from './Banner2';
import Logos from './Logos';
import Gallery from './Gallery';
import ContactForm from './ContactForm';
import GoogleMapWithMarker from './GoogleMapWIthMarker';
import Footer from './Footer';
import ErrorPage from './ErrorPage'; // Import the ErrorPage component

const App = () => {
  return (
    <Router>
      <div>
        <Helmet>
          <title>Byggefirma i Bergen - Karsten Bygg AS</title>
          <meta name="keywords" content="bygg, byggefirma, håndverk, nybygg, tilbygg, rehabilitere bygg, Karsten Bygg AS" />
          <meta name="description" content="Karsten Bygg AS – Ditt pålitelige byggefirma i Bergen. Spesialister i nybygg, tilbygg, renovering og rehabilitering av boliger på Fantoft." />
          <meta property="og:title" content="Byggefirma i Bergen | Karsten Bygg AS" />
          <meta property="og:url" content="https://www.karstenbygg.no/" />
          <meta property="og:type" content="website" />
          <meta property="og:description" content="Karsten Bygg AS – Ditt pålitelige byggefirma i Bergen. Spesialister i nybygg, tilbygg, renovering og rehabilitering av boliger på Fantoft." />
          <meta property="og:image" content="https://www.karstenbygg.no/logo512.png" />
          <link rel="canonical" href="https://www.karstenbygg.no/" />
          <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org",
                "@type": "LocalBusiness",
                "name": "Karsten Bygg AS",
                "url": "https://karstenbygg.no",
                "logo": "https://karstenbygg.no/logo512.png",
                "image": "https://karstenbygg.no/logo512.png",
                "description": "Ledende byggefirma i Bergen kjent for kvalitet og pålitelighet.",
                "telephone": "+47 111 11 111",
                "address": {
                  "@type": "Fantoftvegen",
                  "streetAddress": "Fantoftvegen",
                  "addressLocality": "Bergen",
                  "addressRegion": "Hordaland",
                  "postalCode": "5075",
                  "addressCountry": "NO"
                },
                "geo": {
                  "@type": "GeoCoordinates",
                  "latitude": "60.344310",
                  "longitude": "5.352980"
                },
                "openingHoursSpecification": [
                  {
                    "@type": "OpeningHoursSpecification",
                    "dayOfWeek": [
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday"
                    ],
                    "opens": "08:00",
                    "closes": "17:00"
                  },
                  {
                    "@type": "OpeningHoursSpecification",
                    "dayOfWeek": "Saturday",
                    "opens": "10:00",
                    "closes": "14:00"
                  }
                ],
                "sameAs": [
                  "https://www.facebook.com",
                  "https://www.linkedin.com",
                  "https://www.instagram.com"
                ]
              }
            `}
          </script>
        </Helmet>

        <Navbar />

        <Routes>
          <Route path="/" element={
            <>
              <Home />
              <Banner />
              <Cards />
              <Gallery />
              <Logos />
              <Banner2 />
              <ContactForm />
              <GoogleMapWithMarker />
            </>
          } />
          <Route path="*" status={404} element={<ErrorPage />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
};

export default App;
