import React, { useRef } from 'react';
import './Gallery.css'; // Assuming your CSS is in Gallery_g.css

const Gallery = () => {
    const bannerRef = useRef(null);
  return (
    <div id="prosjekter" ref={bannerRef} className="bggg">
    <div className="container_g">
      <h3 className="h11">Vår Portefølje – Bevis på Vår Ekspertise

</h3>

      <h4 className="gallery-description">Utforsk et utvalg av prosjekter som demonstrerer vår kompetanse som et topp byggefirma i Bergen.

</h4>

      <div className="gallery-wrap_g">
        <div className="item_g item-1_g"></div>
        <div className="item_g item-2_g"></div>
        <div className="item_g item-3_g"></div>
        <div className="item_g item-4_g"></div>
        <div className="item_g item-5_g"></div>
      </div>
    </div>


    </div>
  );
};

export default Gallery;
