// Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section logo">
        <img 
          src="https://cdn.discordapp.com/attachments/985159131184898058/1171894113759662110/Group_1_2.png?ex=655e5671&is=654be171&hm=a1346cfbb7b82aafaf4c77d057c353049faa087c5aea793a5972226af6fc73df&" 
          alt="Company Logo" 
        />
      </div>
      <div className="footer-section details">
        <p>5075 Fantoft, Bergen</p>
        <p>+47 111 11 111</p>
        <p>test@gmail.com</p>
        <h2 className="small">Karsten Bygg AS – Byggefirmaet du kan stole på i Bergen</h2>
      </div>
      <div className="footer-section social-media">
        <a href="https://facebook.com">
          <img 
            src="https://cdn.discordapp.com/attachments/985159131184898058/1172238656669495416/icons8-facebook-50_1.png?ex=655f9752&is=654d2252&hm=8997350ef98700634dbdd55288373e8344585d0c4011229bcefe5b6d69a6c515&" 
            alt="Facebook Icon"
          />
        </a>
        <a href="https://instagram.com">
          <img 
            src="https://cdn.discordapp.com/attachments/985159131184898058/1172238656879206521/icons8-instagram-50_1.png?ex=655f9752&is=654d2252&hm=76d0abeac2458bbdf48d8820a76d6a3dbac5fce68ba9a12fc5ab1e2b7d81814c&" 
            alt="Instagram Icon"
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
