// ErrorPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ErrorPage.css'; // Import the CSS file

const ErrorPage = () => {
  let navigate = useNavigate();

  return (
    <div className="error-container">
      <h1>404: Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <button className="btn primary-btn" onClick={() => navigate('/')}>Go Home</button>
    </div>
  );
};

export default ErrorPage;
