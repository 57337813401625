import React from 'react';
import './Home.css'; // Ensure the path to Home.css is correct

const emailIconUrl = '/icons/email-icon.png';
const phoneIconUrl = '/icons/phone-icon.png';
const locationIconUrl = '/icons/location-icon.png';

const Home = () => {
    const scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          const offsetTop = sectionElement.offsetTop - 100;
          window.scrollTo({
            top: offsetTop,
            behavior: 'smooth'
          });
        }
      };
    
  return (
    <>
      <div className="home-container">
        <div className="left-side">
          <img
            src="https://i.pinimg.com/originals/5a/1d/8a/5a1d8a07b1e2d5334b48df062cc7dbd0.jpg"
            alt="Home img"
            className="background-image"
          />
        </div>
        <div className="right-side">
          <h1 className="home_title">Byggefirma i Bergen - Karsten Bygg AS
</h1>
          <p className="description">
          Oppdag Karsten Bygg AS, Bergen's ledende byggefirma kjent for kvalitet og pålitelighet. Med et sterkt fokus på kundetilfredshet, tilbyr vi en rekke tjenester fra nybygg til renoveringer og tilbygg. Vårt erfarne team sikrer toppmoderne løsninger tilpasset dine unike behov. Stol på oss for ditt neste byggeprosjekt og opplev vår forpliktelse til håndverk og excellence i alle aspekter av konstruksjon.
          </p>
          <div className="buttons-container">
            <button onClick={() => scrollToSection('kontakt')} className="btn primary-btn" >Kontakt oss</button>
            <button onClick={() => scrollToSection('tjenester')}className="btn secondary-btn">Tenester</button>
          </div>
        </div>
      </div>

      <div className="contact-container">
        <div className="contact-item">
          <img src="https://cdn.discordapp.com/attachments/985159131184898058/1171935407760482396/icons8-location-48_1.png?ex=655e7ce6&is=654c07e6&hm=0c82b3d800b1b31afc63f454fbdd035c2be566e4b55e841028bcb365f42597e3&" alt="Location" className="contact-icon" />
          <p> 5075 Fantoft, Bergen</p>
        </div>
        <div className="contact-item">
          <img src="https://cdn.discordapp.com/attachments/985159131184898058/1171935408414806098/icons8-email-48.png?ex=655e7ce6&is=654c07e6&hm=de728c05dad90dd8c2b5e29c21c5979473f4e9eb6a69168d55561bb5efef7b1e&" alt="Email" className="contact-icon" />
          <p>test@gmail.com</p>
        </div>
        <div className="contact-item">
          <img src="https://cdn.discordapp.com/attachments/985159131184898058/1171935408083451995/icons8-phone-48_1.png?ex=655e7ce6&is=654c07e6&hm=cea5f403a9b90e07396f57f652515b736df328537ac133758482a14120f67eb8&" alt="Phone" className="contact-icon" />
          <p>+47 111 11 111</p>
        </div>
      </div>
    </>
  );
};

export default Home;
