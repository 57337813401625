// Logos.js
import React from 'react';
import './Logos.css'; // Assuming you have a CSS file for styling

// Import logo images (assuming they are saved in the src/assets folder)

const Logos = () => {
  return (
    <div className="whole">
    <div class="logos-section">
    <h2 class="logos-title">Kvalitetmerker <span class="logos-amp">&</span> Samarbeidspartnerer</h2>
  <div class="logos-container">
  <img src="https://svn.apache.org/repos/infra/websites/production/ooo-site/content/images/aoo-logo-100x100.png" alt="Lærling logo" className="logo" />
      <img src="https://svn.apache.org/repos/infra/websites/production/ooo-site/content/images/aoo-logo-100x100.png" alt="Godkjent bedrift logo" className="logo" />
      <img src="https://svn.apache.org/repos/infra/websites/production/ooo-site/content/images/aoo-logo-100x100.png" alt="EK logo" className="logo" />
      <img src="https://svn.apache.org/repos/infra/websites/production/ooo-site/content/images/aoo-logo-100x100.png" alt="Byggmester logo" className="logo" />
  </div>
</div>
</div>
  );
};

export default Logos;