// ContactForm.js
import React, { useState, useRef } from 'react';
import './ContactForm.css'; // Make sure this path is correct
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Make sure to install react-fontawesome
import { faRoad, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const ContactForm = () => {
    const bannerRef = useRef(null);
  // State for the form fields
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  // Function to handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would handle the submission, like sending the data to an API
    console.log(formData);
  };

  return (
    <div id="kontakt" ref={bannerRef} className="bgggg">
    <div className="container">
      <h2 className="brand"><span>Trenger du en erfaren snekker til ditt oppdrag?</span></h2>
      <h2 className="brand2"><span>Da har du kommet til rett sted!</span></h2>
      <div className="wrapper">
        <div className="company-info">
          <h3 className="cf_title">Karsten Bygg AS</h3>
          <ul>
            <li><FontAwesomeIcon icon={faRoad} /> Bergen Lagunen</li>
            <li><FontAwesomeIcon icon={faPhone} /> +47 129 22 222</li>
            <li><FontAwesomeIcon icon={faEnvelope} /> test@gmail.com</li>
          </ul>
        </div>
        <div className="contact">
          <h3>Send off ein forespørsel</h3>
          <form id="contact-form" onSubmit={handleSubmit}>
            <p>
              <label htmlFor="name">Navn</label>
              <input type="text" name="name" id="name" required value={formData.name} onChange={handleChange} autoComplete="name" />
            </p>
    
            <p>
              <label htmlFor="email">E-post Addresse</label>
              <input type="email" name="email" id="email" required value={formData.email} onChange={handleChange} autoComplete="email" />
            </p>
            <p>
              <label htmlFor="phone">Telefon</label>
              <input type="text" name="phone" id="phone" value={formData.phone} onChange={handleChange} autoComplete="tel" />
            </p>
            <p className="full">
              <label htmlFor="message">Melding</label>
              <textarea name="message" rows="5" id="message" value={formData.message} onChange={handleChange} autoComplete="off"></textarea>
            </p>
            <p className="full">
              <button type="submit">Send forespørsel</button>
            </p>
          </form>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ContactForm;
