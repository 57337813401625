// Cards.js
import React, { useRef } from 'react';
import './Cards.css'; // Make sure the CSS file name matches this import

// Data for the services, replace with actual data as needed
const services = [
  {
    title: 'Nybygg',
    description: 'Drømmer du om et nytt hjem? Som et ledende byggefirma i Bergen, realiserer vi din visjon med ekspertise og dedikasjon.',
    imageUrl: 'https://vm-bygg.no/wp-content/uploads/2020/02/ToppenFunkis-495x400.png',
  },
  {
    imageUrl: 'https://brunbygg.no/wp/wp-content/uploads/2018/01/IMG_0717.jpg',
    title: 'Tilbygg',
    description: 'Utvid ditt nåværende hjem med hjelp fra Bergens eksperter på tilbygg – Karsten Bygg AS. Vi håndterer prosessen fra A til Å.    '
  },
  {
    imageUrl: 'https://sandaasbygg.no/wp-content/uploads/2023/01/rehabilitering-sandaas-bygg.jpg',
    title: 'Rehabilitering',
    description: 'Oppgrader ditt hjem med våre rehabiliteringstjenester. Vi gjenoppliver bygninger med kvalitetsarbeid og oppmerksomhet på detaljer.'
  },
  {
    imageUrl: 'https://vm-bygg.no/wp-content/uploads/2020/02/sdb-1881206_1280-495x400.jpg',
    title: 'Arkitekttjenester',
    description: 'Våre arkitekttjenester i Bergen sikrer at ditt byggeprosjekt er både estetisk tiltalende og funksjonelt solid.'
  },
  {
    imageUrl: 'https://s3.eu-north-1.amazonaws.com/codex-nettside/images/fag_entreprise/totalentreprise2.jpg',
    title: 'Totalentrepriser',
    description: 'For en helhetlig byggeopplevelse, tilbyr vi totalentrepriser. Vi er byggmesteren som håndterer alle aspekter av ditt byggeprosjekt i Bergen.'
  },
  // ... add more services here if necessary
];

const Cards = () => {
    const scrollToContact = () => {
        const contactSection = document.getElementById('kontakt');
        if (contactSection) {
            const offsetTop = contactSection.offsetTop - 100;
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth'
            });
        }
    };

    const bannerRef = useRef(null);
    return (
      <div id="tjenester" ref={bannerRef} className="bg_card">
        <div className="service-container">
          <h2 className="cards_title">Byggmester Tjenester i Bergen</h2>
          <div className="service-card-container">
            {services.map((service, index) => (
              <div className="service-card" key={index}>
                <img src={service.imageUrl} alt={service.title} />
                <h3 className="service-title">{service.title}</h3>
                <p className="service-description">{service.description}</p>
                <button onClick={scrollToContact} className="service-button">Kontakt</button>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  
export default Cards;