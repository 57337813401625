import React, { useState } from 'react';
import './Navbar.css'; // Make sure the path to Navbar.css is correct

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      const offsetTop = sectionElement.offsetTop - 100; // 100px offset for the navbar
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth'
      });
      setIsOpen(false); // Close the menu when a button is clicked
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle the menu open/close when the hamburger button is clicked
  };

  return (
    <header className="navbar">
    <nav className="navbar">
      <div className="nav-container">
        {/* Logo that links to home */}
        <a href="/" className="logo-nav">
          <img
            src="https://cdn.discordapp.com/attachments/985159131184898058/1171894113759662110/Group_1_2.png?ex=655e5671&is=654be171&hm=a1346cfbb7b82aafaf4c77d057c353049faa087c5aea793a5972226af6fc73df&"
            alt="Logo"
          />
        </a>
        <button className="nav-toggle" onClick={toggleMenu}>
          <span className={`hamburger ${isOpen ? "active" : ""}`}></span>
        </button>
        <div className={`nav-items ${isOpen ? "active" : ""}`}>
          <a onClick={() => scrollToSection('om-oss')} className="nav-item">
            Om oss
          </a>
          <a onClick={() => scrollToSection('tjenester')} className="nav-item">
            Tjenester
          </a>
          <a onClick={() => scrollToSection('prosjekter')} className="nav-item">
            Prosjekter
          </a>
          <a onClick={() => scrollToSection('kontakt')} className="nav-item">
            Kontakt oss
          </a>
        </div>
      </div>
    </nav>
    </header>
  );
};

export default Navbar;
